import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Other_Platforms/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software für Ihre INSTAR IP Kamera",
  "path": "/Software/Other_Platforms/ASUSTOR_Surveillance_Center/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "Alle INSTAR IP-Kameras werden von der Asustor Surveillance Station unterstützt. Verwalten Sie alle Ihre Kameras und Videoaufnahmen.",
  "image": "./P_SearchThumb_Synology.png",
  "social": "/images/Search/P_SearchThumb_Synology.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Other_Asustor_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='Camera Surveillance Software' dateChanged='2017-12-08' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use our free mobile apps for Android, iOS, Windows Phone or Blackberry. Or use the capabilities of our InstarVision 2 Surveillance Station.' image='/images/Search/P_SearchThumb_Products.png' twitter='/images/Search/P_SearchThumb_Products.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Other_Platforms/ASUSTOR_Surveillance_Center/' locationFR='/fr/Software/Other_Platforms/ASUSTOR_Surveillance_Center/' crumbLabel="Asustor" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "other",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#other",
        "aria-label": "other permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Other`}</h2>
    <h3 {...{
      "id": "asustor",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#asustor",
        "aria-label": "asustor permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Asustor`}</h3>
    <h2 {...{
      "id": "asustor-surveillance-center",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#asustor-surveillance-center",
        "aria-label": "asustor surveillance center permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Asustor Surveillance Center`}</h2>
    <div className="onecard">
  <a href="/Software/Other_Platforms/Video/Asustor_Add_Cameras/">
    <div className="image">
        <img alt="IP Kamera hinzufügen beim ASUSTOR Surveillance Center" width="715" height="536" border="10" src="/images/Motion_Detection/Synology_Add_Camera.png" />
    </div>
  </a>
    <div className="action">
            <p>IP Kamera hinzufügen beim ASUSTOR Surveillance Center</p>
    </div>
    </div>
    <div className="onecard">
  <a href="/Software/Other_Platforms/Video/Asustor_FTP_Upload/">
    <div className="image">
        <img alt="FTP Upload bei einer ASUSTOR NAS Station einrichten" width="715" height="536" border="10" src="/images/Motion_Detection/Synology_FTP_Server.png" />
    </div>
  </a>
    <div className="action">
            <p>FTP Upload bei einer ASUSTOR NAS Station einrichten</p>
    </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      